import React, { Fragment } from "react";
import "./what-we-do.scss";
import { Heading } from "../Heading/Heading";
import { Button } from "../Button/Button";
import { ArrowRight } from "react-feather";
import { graphql, useStaticQuery } from "gatsby";
import { ExpandableServiceCard } from "../ExpandableServiceCard/ExpandableServiceCard";

const IMAGE_QUERY = graphql`
  {
    dartImage: file(relativePath: { eq: "homepage/icons/dart.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 48
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    designImg: file(relativePath: { eq: "homepage/icons/design.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 48
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mobileAppImg: file(relativePath: { eq: "homepage/icons/mobile-app.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 48
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    consultingImg: file(
      relativePath: { eq: "homepage/icons/consulting-people.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 48
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    developmentImg: file(
      relativePath: { eq: "homepage/icons/development.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 48
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export const WhatWeDoSection = ({ title, description, btnLink }) => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <section className="what-we-do" id="what-we-do">
      <div className="container">
        <div className="gr-12 no-gutter@md">
          <div className="what-we-do__main-holder">
            <div className="what-we-do__text">
              <Heading level="h2" decorationDown animated>
                {title}
              </Heading>
              <p className="subtitle">{description}</p>

              <Button
                link={btnLink}
                secondary
                withIcon
                icon={
                  <Fragment>
                    <ArrowRight size="24" />
                  </Fragment>
                }
              >
                Explore our services
              </Button>
            </div>
            <div className="what-we-do__services">
              <div className="holder">
                <div className="inner">
                  <div className="column">
                    <ExpandableServiceCard
                      title="Strategy"
                      description="It is essential to first assess and understand your
                      unique business environment and objectives."
                      icon={tempImage.dartImage}
                    />
                    <ExpandableServiceCard
                      title="UX/UI Design"
                      description=" We create user-friendly interfaces that not only look
                      aesthetically pleasing but also provide an intuitive
                      user experience."
                      icon={tempImage.designImg}
                    />
                    <ExpandableServiceCard
                      title="Mobile App"
                      description="Development and maintenance of high-quality mobile
                      applications for both iOS and Android platforms."
                      icon={tempImage.mobileAppImg}
                    />

                  </div>
                  <div className="column">
                    <ExpandableServiceCard
                      title="Consulting"
                      description="We provide professional advice and guidance to clients
                      on various aspects of technology."
                      icon={tempImage.consultingImg}
                    />
                    <ExpandableServiceCard
                      title=" Web Development"
                      description="Creation and maintenance of visually appealing and
                      functional websites and web applications."
                      icon={tempImage.developmentImg}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
